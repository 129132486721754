import { useEffect, useState } from "react";

const PolicyContent = () => {
  const [emailAddress, setEmailAddress] = useState("info@polycareers.com.mk");
  const domainName = window.location.hostname;

  useEffect(() => {
    if (domainName === "polycareers.com") {
      setEmailAddress("info@polycareers.com");
    } else {
      setEmailAddress("info@polycareers.com.mk");
    }
  }, [domainName]);

  return (
    <>
      <section className="term-condition">
        <div className="main-heading-term">Privacy Policy</div>
      </section>
      <section>
        <div className="container container-site">
          <div className="sub-heading-term">Privacy Notice</div>
          <div className="first-sub-para-term">
            Thank you for your interest in PolyCareers! We're excited to
            announce that our job board will be launching soon. To keep you
            updated on our progress and notify you when we officially launch, we
            need to collect your email address.
          </div>
          <div className="sub-heading-term">How We Use Your Email Address</div>
          <div className="first-sub-para-term">
            We will only use your email address to send you updates about the
            launch of PolyCareers and related announcements. Your email address
            will not be shared with any third parties or used for any other
            purposes without your explicit consent.
          </div>
          <div className="sub-heading-term">Your Privacy Rights</div>
          <div className="first-sub-para-term">
            You have the right to unsubscribe from our email updates at any
            time. Simply click the unsubscribe link in any email you receive
            from us, or contact us directly using the information provided
            below.
          </div>
          <div className="sub-heading-term">Contact Us</div>
          <div className="first-sub-para-term">
            If you have any questions or concerns about how we handle your email
            address or our privacy practices, please don't hesitate to contact
            us at {emailAddress}{" "}
          </div>
          <div className="sub-heading-term">Stay Connected</div>
          <div className="first-sub-para-term">
            Follow us on{" "}
            <a
              href="https://www.linkedin.com/in/virginiapoly/recent-activity/all/"
              target="_blank"
            >
              LinkedIn
            </a>
            ,{" "}
            <a href="https://www.instagram.com/polytechtalent/" target="_blank">
              Instagram
            </a>
            ,{" "}
            <a
              href="https://www.facebook.com/PolyTechTalentPage"
              target="blank"
            >
              Facebook
            </a>{" "}
             for additional updates and news about PolyCareers.
          </div>
        </div>
      </section>
    </>
  );
};

export default PolicyContent;
