import React from "react";
import PolicyContent from "../../components/PrivacyPolicy/PolicyContent";
import "./index.css";

const PrivacyPolicy = () => {
  return (
    <>
      <PolicyContent />
    </>
  );
};

export default PrivacyPolicy;
