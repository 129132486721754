const LearnMore = () => {
  return (
    <section className="collision-sec">
    <div className="container container-site">
      <div className="left-text-collision">
        <p className="collision-text-para">
          We’re thrilled to announce that PolyCareers will be featured at
          this year’s Collision Conference, happening June 17-20. Join us in
          the Startup section where we’ll showcase our innovative AI driven
          job platform.{" "}
        </p>
        <p className="collision-text-para">
          Don’t miss the opportunity to experience the future of recruitment
          technology.
        </p>
        <p className="collision-text-para">
          We look forward to meeting you at Collision 2024
        </p>
        <a
          className="collosion-btn"
          href="https://collisionconf.com/"
          target="_blank"
        >
          Learn more
        </a>
      </div>
    </div>
  </section>
  );
};

export default LearnMore;
