import OutsideClickHandler from "react-outside-click-handler";
import "./EmailSuccessModal.css";

const EmailSuccessModal = ({ resMsg, modelHandler }) => {
  return (
    <>
      <section className="model-popup-comingsoon wishlist-box">
        <div className="popup-bg-cmingsoon">
          <OutsideClickHandler
            onOutsideClick={() => {
              modelHandler();
            }}
          >
            <div className="popup-content-cmingsoon">
              <div className="close-cmingsoon">
                <img
                  src="/image/cut-sm.png"
                  alt=" "
                  onClick={() => modelHandler(false)}
                />
              </div>
              <div className="uper-part-cmingsoon">
                <div className="cmingsoon-logo">
                  <img src="/image/PolyCareers.png" alt=" " />
                </div>
                <div className="cmingsoon-logo-model">
                  <img src="./image/coming-tick.png" alt=" " />
                </div>
                <p className="text-cmingsoon-succes">
                  {resMsg !== "" ? resMsg : ""}
                </p>
              </div>
              <div className="lower-cmingsoon-success">
                <p className="text-cmingsoon-sucess">
                  Connecting the world to work
                </p>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </section>
    </>
  );
};

export default EmailSuccessModal;
