import Footer from "./Footer/Footer";
import TopNavBar from "./TopNavBar/TopNavBar";

const Layout = ({children}) => {
  return (
    <div className="all-page-bg-clr">
      <TopNavBar />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
