const MeetFounder = () => {
  return (
    <section className="bottom-sec-coming">
      <div className="text-bottom-coming">
        <p className="bottom-heading-coming">Meet the founders</p>
        <p className="bottom-text-coming">
          Founded by well-known and seasoned experts in the recruitment and
          talent industry, the platform benefits from deep expertise and
          experience hiring top talent for hundreds of top brands and
          organizations across North America
        </p>
      </div>
      <img src="/image/meet-founder.png" alt="" className="mbl-group" />
      <div className="container container-site">
        <div className="founder-name">
          <div className="name-left-founder">
            <a
              href="https://www.linkedin.com/in/virginiapoly/"
              className="left-founder"
              target="_blank"
            >
              Virginia Poly.
            </a>
          </div>
          <div className="name-right-founder">
            <a
              href="https://www.linkedin.com/in/asif-chowdhary/"
              className="right-founder"
              target="_blank"
            >
              Asif Chowdhary
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetFounder;
