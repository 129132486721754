import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import MeetFounder from "../../components/Home/MeetFounder";
import LearnMore from "../../components/Home/LearnMore";
import EmailSuccessModal from "../../components/Common/EmailSuccessModal/EmailSuccessModal";
import API from "../../api";
import "./index.css";

const Home = () => {
  const headRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [toggleModel, setToggleModel] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [userType, setUserType] = useState("");
  const [msg, setMsg] = useState("");
  const [resMsg, setResMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email address"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { email } = data;
      try {
        const userDomain = window.location.hostname;
        const userCountry = await getUserCountry();
        const { browser, os } = getUserBrowserAndOS();

        if (userType === "") {
          setMsg("Please select one of the above options");
          setErrorMsg(true);
          setLoader(false);

          setTimeout(() => {
            setMsg("");
            setErrorMsg(false);
          }, 3000);

          return;
        } else {
          setLoader(true);
          const formData = new FormData();
          formData.append("email", email.trim());
          formData.append("user_type", userType);
          formData.append("url", userDomain);
          formData.append("country", userCountry);
          formData.append("browser", `${browser}`);
          formData.append("operating_system", `${os}`);

          await API.post(`/comingsoon.php`, formData)
            .then((response) => {
              const { data } = response;
              if (response?.status === 200 || response?.status === 201) {
                modelHandler(true);
                setLoader(false);
                if (data?.result === true) {
                  setResMsg(data?.message?.success);
                } else if (data?.result === false) {
                  setResMsg(data?.message?.error);
                }
              }

              setTimeout(() => {
                setUserType("");
                modelHandler(false);
                setLoader(false);
                setResMsg("");
                formik.resetForm();
              }, 3000);
            })
            .catch((error) => {
              if (error) {
                modelHandler(false);
                setLoader(false);
                setResMsg("");
                console.log(error);
              }
            });
        }
      } catch (error) {
        modelHandler(false);
        setLoader(false);
        setResMsg("");
        console.log(error);
      }
    },
  });

  const modelHandler = (v) => setToggleModel(v);

  const getUserCountry = async () => {
    try {
      const response = await axios.get("https://ipinfo.io/json");
      const { country } = response.data;
      return country;
    } catch (error) {
      console.error("Error fetching user's country:", error);
      return null;
    }
  };

  const getUserBrowserAndOS = () => {
    const userAgent = navigator.userAgent;
    const browser = (userAgent &&
      userAgent.match(/(chrome|firefox|safari|edge|opera)/i)) || [
      "Unknown Browser",
    ];
    const os = (userAgent &&
      userAgent.match(/(windows|mac os|android|iphone|linux)/i)) || [
      "Unknown OS",
    ];
    return {
      browser: browser[0],
      os: os[0],
    };
  };

  const handleJobSeekerCheckboxChange = (event) => {
    setUserType(event.target.checked ? "candidate" : "");
  };

  const handleEmployerCheckboxChange = (event) => {
    setUserType(event.target.checked ? "employer" : "");
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const scrollToHead = () => {
    if (headRef.current) {
      headRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <section className="comming-soon" ref={headRef}>
        <div className="container container-site">
          <h1 className="coomig-soon-heading">
            This is the job board you’ve been waiting for.
          </h1>
          <div className="img-fprm-comming-soon">
            <div className="yello-img-left">
              <img src="/image/comming-soon.png" alt="" />
            </div>
            <div className="right-form-commingsoon">
              <p className="free-job-posting">
                {" "}
                Sign up now and get 5 free job postings.
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div
                  className={`emil-subscribe-soon ${
                    isFormFieldValid("email") && "error-input"
                  }`}
                >
                  <input
                    type="email"
                    name="email"
                    id=""
                    className="email-form"
                    placeholder="Enter your email address here"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <button type="submit" className="subscribe-btn">
                    {loader ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Subscribe"
                    )}
                  </button>
                  {getFormErrorMessage("email")}
                </div>
              </form>
              <div className="selct-form-soon">
                <div className="check-box-comming-soon">
                  <input
                    type="radio"
                    name=""
                    id=""
                    className="chek-job-post icon-cursor"
                    onChange={handleJobSeekerCheckboxChange}
                    checked={userType === "candidate"}
                  />
                  <label for="">
                    If you’re a job seeker click here and be the first to
                    explore our amazing resume builder tools.
                  </label>
                </div>
                <div className="check-box-comming-soon">
                  <input
                    type="radio"
                    name=""
                    id=""
                    className="chek-job-post icon-cursor"
                    onChange={handleEmployerCheckboxChange}
                    checked={userType === "employer"}
                  />
                  <label for="">
                    If you’re an employer click here and receive 5 free job
                    postings at launch.
                  </label>
                  {/* {errorMsg && <p className="error-msg">{msg}</p>} */}
                </div>
                {errorMsg && <p className="error-msg-soon">{msg}</p>}
              </div>
              <p className="info-comming">
                Your information is protected. You can view our{" "}
                <Link to="/privacy-policy">privacy policy</Link> here.
              </p>
            </div>
          </div>
          <div className="seacond-row-coming">
            <h2 className="coomig-soon-heading-two">
              A refreshingly <span>simple way to hire.</span>{" "}
            </h2>
            <p className="job-posting-text">
              PolyCareers is an AI-driven job portal that helps companies and
              candidates <span>move through the hiring process easily</span>.
            </p>
            <div className="tree-img-comingsoon">
              <img src="/image/tree-coming-soon.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="laptop-sec">
        <img src="/image/three-groups.png" alt="" className="desktop-img" />
        <img src="/image/mbl-group-img.png" alt="" className="mbl-group" />
        <button className="register-btn" onClick={() => scrollToHead()}>
          Register now and try it for free
        </button>
      </section>

      <section className="laptop-sec">
        <img
          src="/image/laptop-Coming-soon.png"
          alt=""
          className="desktop-img laptop-tabledata"
        />
        <img src="/image/laptop-mbl-img.png" alt="" className="mbl-group" />
      </section>

      <section className="laptop-sec">
        <img src="/image/hader-black.png" alt="" />
      </section>

      <LearnMore />
      <MeetFounder />

      {toggleModel && (
        <EmailSuccessModal resMsg={resMsg} modelHandler={modelHandler} />
      )}
    </>
  );
};

export default Home;
