import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const TopNavBar = () => {
  const [countryName, setCountryName] = useState("North Macedonia");
  const domainName = window.location.hostname;

  useEffect(() => {
    if (domainName === "polycareers.com") {
      setCountryName("Canada");
    } else {
      setCountryName("North Macedonia");
    }
  }, [domainName]);

  return (
    <header>
      <div className="container container-site">
        <div className=" row-log ">
          <div className="logo-col ">
            <Link to="/">
              <img src="/image/polycareers-logo.png" alt="logo " />
            </Link>
            <p className="country-name-logo">{countryName}</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopNavBar;
