import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PublicRoutes } from "./PublicRoutes";
import Home from "../pages/Home";
import PrivacyPolicy from "../pages/PrivacyPolicy";



const CustomRouter = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Home />} />   
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />           
        </Route>
      </Routes>
    </Router>
  );
};

export default CustomRouter;
